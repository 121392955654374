import React, { lazy } from "react";
import { privateRoute, redirectRoute, route } from "../helpers/utils";
const Admin = lazy(() => import("../components/Admin"));
const Campaign = lazy(() => import("../components/Campaign"));
const FormsContainer = lazy(() => import("../components/FormsContainer"));
const AdminLogin = lazy(() => import("../components/AdminLogin"));
const Episode = lazy(() => import("../components/episodes/Episode"));
const App = lazy(() => import("../App"));
const ApiKeys = lazy(() => import("../components/ApiKeys"));
const CreateInBatch = lazy(() => import("../components/createInBatch/CreateInBatch"));
const CreatePredicion = lazy(() => import("../components/CreatePrediction"));
const CreateCompany = lazy(() => import("../components/CreateCompany"));
const FeedbackList = lazy(() => import("../components/FeedbackList"));
const ValidationKey = lazy(() => import("../components/ValidationKey"));
const ValidationKeyDashboard = lazy(() => import("../components/ValidationKeyDashboard"));
const UserPage = lazy(() => import("../components/users/UserPage"));
const UserPosts = lazy(() => import("../components/users/UserPosts"));
const Games = lazy(() => import("../components/Games"));
const Feed = lazy(() => import("../components/feed/FeedTool"));

const routers = [
  privateRoute(App, "/companies"),
  privateRoute(Admin, "/admin/company/:companyId"),
  privateRoute(Campaign, "/admin/company/:companyId/campaign/:campaignId"),
  privateRoute(
    UserPage,
    "/admin/company/:companyId/campaign/:campaignId/user/:userId"
  ),
  privateRoute(
    UserPosts,
    "/admin/company/:companyId/campaign/:campaignId/user/:userId/posts"
  ),
  privateRoute(FeedbackList, "/admin/company/:companyId/feedback"),
  privateRoute(FormsContainer, "/admin/forms"),
  privateRoute(CreateCompany, "/admin/createCompany"),
  route(ValidationKey, "/validation/validate-key/:validationkey?", {
    layout: false,
  }),
  route(ValidationKeyDashboard, "/validationKey/dashboard/:validationKey"),
  privateRoute(Episode, "/episode/:episodeId"),
  privateRoute(ApiKeys, "/api-keys"),
  privateRoute(CreateInBatch, "/createInBatch"),
  privateRoute(CreatePredicion, "/createPrediction"),
  privateRoute(Games, "/games"),
  privateRoute(Feed, "/feed"),
  redirectRoute(AdminLogin, "/", { layout: false }),

  route(() => <h1 className="mt-2">Page not found</h1>),
];

export default routers;
