import axios from "axios";
import { getToken, isValidToken, removeToken } from "./token";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api/admin/`,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});

axiosInstance.interceptors.request.use((req) => {
  if (req.url === "/session" && !isValidToken()) {
    throw new Error("Invalid session token");
  }
  if (isValidToken()) req.headers.authorization = getToken();

  return req;
});

axiosInstance.interceptors.response.use(
  (res) => res?.data || {},
  (err) => {
    if (err.code === "ERR_NETWORK") return Promise.reject(err.message);

    if (
      (err.response.data.statusCode === 401 || err.response.status === 401) &&
      err.response.config.url !== "/session" // The private router check this
    ) {
      removeToken();
      window.location.href = "/";
    }
    return Promise.reject(err);
  }
);

export const loginAdmin = async (auth) => {
  const res = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/api/admin/login`,
    {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(auth),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    }
  );

  if (!res.ok) throw new Error("Error, check your credentials");
  const data = await res.json();

  return data;
};

export async function createWordle(data) {
  const res = await axiosInstance.post("wordleGame", data);
  return res;
}

export async function getWordles(params) {
  const res = await axiosInstance.get("wordleGame", { params: params?.query });
  return res;
}

export async function editWordle(id, data) {
  const res = await axiosInstance.put(`wordleGame/${id}`, data);
  return res;
}

export async function deleteWordle(id) {
  const res = await axiosInstance.delete(`wordleGame/${id}`);
  return res;
}

export async function getUserFCMTokens(params) {
  const res = await axiosInstance.get("fcmTokens", { params: params?.query });
  return res;
}

export async function sendFCMNotification({ tokens, title, body }) {
  const res = axiosInstance.post("sendNotification", {
    tokens,
    data: {
      notification: {
        title,
        body,
      },
    },
  });
  return res;
}

export async function changeCategoryOrder(data) {
  const res = await axiosInstance.put("categorySubmission/order", data);
  return res;
}

export async function postCategorySubmission(data) {
  const res = await axiosInstance.post("categorySubmission", data);
  return res;
}

export async function getCategoriesSubmission(params) {
  const res = await axiosInstance.get("categorySubmission", {
    params: params?.query,
  });
  return res;
}

export async function putCategorySubmission({ id, data }) {
  const res = await axiosInstance.put(`categorySubmission/${id}`, data);
  return res;
}

export async function deleteCategorySubmission(id) {
  const res = await axiosInstance.delete(`categorySubmission/${id}`);
  return res;
}

export async function postBanner(data) {
  const res = await axiosInstance.post("banner", data);
  return res;
}

export async function getBanners(params) {
  const res = await axiosInstance.get("banner", { params: params?.query });
  return res;
}

export async function putBanner({ id, data }) {
  const res = await axiosInstance.put(`banner/${id}`, data);
  return res;
}

export async function deleteBanner(id) {
  const res = await axiosInstance.delete(`banner/${id}`);
  return res;
}

export async function checkValidationKey(data) {
  const res = await axiosInstance.get("validationKey/validate", {
    params: data,
  });
  return res;
}

export async function postValidationKey(data) {
  const res = await axiosInstance.post("validationKey", data);
  return res;
}

export async function getValidationKeys(params) {
  const res = await axiosInstance.get("validationKey", {
    params: params?.query,
  });
  return res;
}

export async function getValidationKey() {
  const res = await axiosInstance.get("validationKey");
  return res;
}

export async function getValidationKeyDashboard(validationKey) {
  const res = await axiosInstance.get(
    `validationKey/dashboard/${validationKey}`
  );
  return res;
}

export async function putValidationKey({ id, data }) {
  const res = await axiosInstance.put(`validationKey/${id}`, data);
  return res;
}

export async function deleteValidationKey(id) {
  const res = await axiosInstance.delete(`validationKey/${id}`);
  return res;
}

export async function setCronbJob(data) {
  const res = await axiosInstance.post("schedule", data);
  return res;
}

// prediction
export async function postPredictionCreate(params) {
  const response = await axiosInstance.post(
    "/predictions/create",
    params?.body
  );
  return response;
}

export async function postStory(data) {
  return await axiosInstance.post("story", data);
}

export async function updateStory({ storyId, data }) {
  return await axiosInstance.put(`story/${storyId}`, data);
}

export async function deleteStory(storyId) {
  return await axiosInstance.delete(`story/${storyId}`);
}

export async function getStories(query) {
  return await axiosInstance.get("story", {
    params: query,
  });
}

// daily rewards
export async function postDailyReward(params) {
  const response = await axiosInstance.post("consistencyReward", params?.body);
  return response;
}

export async function getDailyRewards(params) {
  const response = await axiosInstance.get("consistencyReward", {
    params: params?.query,
  });
  return response;
}

export async function putDailyReward(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(
    `consistencyReward/${params.path.id}`,
    params?.body,
    {
      params: params?.query,
    }
  );
  return response;
}

export async function deleteDailyRewardId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(
    `consistencyReward/${params.path.id}`,
    {
      params: params?.query,
    }
  );
  return response;
}

// trivia
export async function postTriviaQuestion(params) {
  const response = await axiosInstance.post("triviaQuestion", params?.body);
  return response;
}

export async function getTriviaQuestions(params) {
  const response = await axiosInstance.get("triviaQuestion", {
    params: params?.query,
  });
  return response;
}

//batch
export async function getModelsNames() {
  const res = await axiosInstance.get("modelsNames");
  return res;
}

export async function postBatch({ path, batch }) {
  console.log(path, batch);
  const res = await Promise.all(
    batch.map((data) => axiosInstance.post(path, data))
  );
  return res;
}

export async function putTriviaQuestion(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(
    `triviaQuestion/${params.path.id}`,
    params?.body,
    {
      params: params?.query,
    }
  );
  return response;
}

export async function deleteTriviaQuestionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(
    `triviaQuestion/${params.path.id}`,
    {
      params: params?.query,
    }
  );
  return response;
}

// aws
export async function postAwsSignUrl(params) {
  const response = await axiosInstance.post("aws/signUrl", params?.body);
  return response;
}

export async function getAdmin(params) {
  const response = await axiosInstance.get("admin", { params: params?.query });
  return response;
}

/**
 * return the admin created
 */
export async function postAdmin(params) {
  const response = await axiosInstance.post("admin", params?.body);
  return response;
}

export async function getCampaignsByCompany(companyId) {
  const response = await axiosInstance.get(`campaign?company_id=${companyId}`);
  return response;
}

export async function getCampaign(params) {
  const response = await axiosInstance.get("campaign", {
    params: params?.query,
  });
  return response;
}

export async function postCampaign(params) {
  const response = await axiosInstance.post("campaign", params?.body);
  return response;
}

export async function getCompany(params) {
  const response = await axiosInstance.get("company", {
    params: params?.query,
  });
  return response;
}

export async function postCompany(params) {
  const response = await axiosInstance.post("company", params?.body);
  return response;
}

export async function getEpisode(params) {
  const response = await axiosInstance.get("episode", {
    params: {
      campaign_id: params?.query.campaign_id,
    },
  });
  return response;
}

export async function postEpisode(params) {
  const response = await axiosInstance.post("episode", params?.body, {
    params: params?.query,
  });
  return response;
}

export async function getEpisodeMission(params) {
  const response = await axiosInstance.get("episodeMission", {
    params: {
      ...params?.query,
    },
  });
  return response;
}

export async function postEpisodeMission(params) {
  const response = await axiosInstance.post("episodeMission", params?.body);
  return response;
}

export async function getEpisodeMissionSubmission(params) {
  const response = await axiosInstance.get("episodeMissionSubmission", {
    params: params?.query,
  });
  return response;
}

export async function postEpisodeMissionSubmission(params) {
  const response = await axiosInstance.post(
    "episodeMissionSubmission",
    params?.body
  );
  return response;
}
export async function getDashboard(params) {
  const response = await axiosInstance.get("dashboard", {
    params: params?.query,
  });
  return response;
}

export async function getLevel(params) {
  const response = await axiosInstance.get("level", { params: params?.query });
  return response;
}

export async function postLevel(params) {
  const response = await axiosInstance.post("level", params?.body);
  return response;
}

export async function getReward(params) {
  const response = await axiosInstance.get("reward", { params: params?.query });
  return response;
}

export async function postReward(params) {
  const response = await axiosInstance.post("reward", params?.body);
  return response;
}

export async function getRewardCollection(params) {
  const response = await axiosInstance.get("rewardCollection", {
    params: params?.query,
  });
  return response;
}

export async function postRewardCollection(params) {
  const response = await axiosInstance.post("rewardCollection", params?.body);
  return response;
}

export async function getTemporalMission(params) {
  const response = await axiosInstance.get("temporalMission", {
    params: params?.query,
  });
  return response;
}

export async function getTemporalMissionWithDates(params) {
  const response = await axiosInstance.get("temporalMissionWithDates", {
    params: params,
  });
  return response;
}

export async function postTemporalMission(params) {
  const response = await axiosInstance.post("temporalMission", params?.body);
  return response;
}

export async function getTemporalMissionSubmission(params) {
  const response = await axiosInstance.get("temporalMissionSubmission", {
    params: params?.query || params,
  });
  return response;
}

export async function postTemporalMissionSubmission(params) {
  const response = await axiosInstance.post(
    "temporalMissionSubmission",
    params?.body
  );
  return response;
}

export async function getAdminId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.get(`admin/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function putAdminId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(
    `admin/${params.path.id}`,
    params?.body,
    {
      params: params?.query,
    }
  );
  return response;
}

export async function deleteAdminId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(`admin/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function getCampaignId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.get(`campaign/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function putCampaignId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(
    `campaign/${params.path.id}`,
    params?.body,
    {
      params: params?.query,
    }
  );
  return response;
}

export async function deleteCampaignId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(`campaign/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function getCompanyId(companyId) {
  const response = await axiosInstance.get(`company/${companyId}`);
  return response;
}

export async function putCompanyId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const url = `${process.env.REACT_APP_SERVER_URL}/company/${params.path.id}`;
  const response = await axiosInstance.put(url, params?.body, {
    params: params?.query,
  });
  return response;
}

export async function deleteCompanyId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }

  const response = await axiosInstance.delete(`company/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function getEpisodeId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.get(`episode/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function putEpisodeId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(
    `episode/${params.path.id}`,
    params?.body,
    {
      params: params?.query,
    }
  );
  return response;
}

export async function deleteEpisodeId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(`episode/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function getEpisodeMissionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.get(`episodeMission/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function putEpisodeMissionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(
    `episodeMission/${params.path.id}`,
    params?.body,
    {
      params: params?.query,
    }
  );
  return response;
}

export async function deleteEpisodeMissionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(
    `episodeMission/${params.path.id}`,
    { params: params?.query }
  );
  return response;
}

export async function postPost(postBody) {
  const response = await axiosInstance.post("post", postBody);
  return response;
}

export async function getPost({ campaign_id, author }) {
  const response = await axiosInstance.get(`post`, {
    params: { campaign_id, author },
  });
  return response;
}

export async function putPostId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(
    `post/${params.path.id}`,
    params?.body,
    {
      params: params?.query,
    }
  );
  return response;
}

export async function deletePostId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(
    `post/${params.path.id}`,
    { params: params?.query }
  );
  return response;
}

export async function getReports(params) {
  const response = await axiosInstance.get(`report`, {
    params: params?.query,
  });
  return response;
}

export async function putReportId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(
    `report/${params.path.id}`,
    params?.body,
    {
      params: params?.query,
    }
  );
  return response;
}


export async function getEpisodeMissionSubmissionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.get(
    `episodeMissionSubmission/${params.path.id}`,
    { params: params?.query }
  );
  return response;
}

export async function putEpisodeMissionSubmissionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(
    `episodeMissionSubmission/${params.path.id}`,
    params?.body,
    {
      params: params?.query,
    }
  );
  return response;
}

export async function deleteEpisodeMissionSubmissionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(
    `episodeMissionSubmission/${params.path.id}`,
    { params: params?.query }
  );
  return response;
}

export async function getLevelId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.get(`level/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function putLevelId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(
    `level/${params.path.id}`,
    params?.body,
    {
      params: params?.query,
    }
  );
  return response;
}

export async function deleteLevelId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(`level/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function getRewardId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.get(`reward/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function putRewardId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(
    `reward/${params.path.id}`,
    params?.body,
    {
      params: params?.query,
    }
  );
  return response;
}

export async function deleteRewardId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(`reward/${params.path.id}`, {
    params: { ...params?.query, level_id: params.path.levelId },
  });
  return response;
}

export async function getRewardCollectionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.get(
    `rewardCollection/${params.path.id}`,
    {
      params: params?.query,
    }
  );
  return response;
}

export async function putRewardCollectionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(
    `rewardCollection/${params.path.id}`,
    params?.body,
    {
      params: params?.query,
    }
  );
  return response;
}

export async function deleteRewardCollectionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(`reward/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}
export async function getTemporalMissionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.get(
    `temporalMission/${params.path.id}`,
    { params: params?.query }
  );
  return response;
}

export async function putTemporalMissionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(
    `temporalMission/${params.path.id}`,
    params?.body,
    {
      params: params?.query,
    }
  );
  return response;
}

export async function deleteTemporalMissionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(
    `temporalMission/${params.path.id}`,
    { params: params?.query }
  );
  return response;
}

export async function getTemporalMissionSubmissionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.get(
    `temporalMissionSubmission/${params.path.id}`,
    { params: params?.query }
  );
  return response;
}

export async function putTemporalMissionSubmissionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(
    `temporalMissionSubmission/${params.path.id}`,
    params?.body,
    {
      params: params?.query,
    }
  );
  return response;
}

export async function deleteTemporalMissionSubmissionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(
    `temporalMissionSubmission/${params.path.id}`,
    { params: params?.query }
  );
  return response;
}

export const createApikey = async () => {
  console.log("post");
  const response = await axiosInstance.post("/api-key");
  return response;
};

export const getApikeys = async () => {
  const response = await axiosInstance.get("/api-key");
  return response;
};

export const deleteApikey = async (id) => {
  const response = await axiosInstance.delete(`/api-key/${id}`);
  return response;
};

export const createDisccountCode = async (data) => {
  const res = await axiosInstance.post("/disccountCode", data);
  return res;
};

export const getDisccountCodes = async (params) => {
  const res = await axiosInstance.get("/disccountCode", {
    params: params?.query,
  });
  return res;
};

export const getDisccountCodeById = async (id) => {
  const res = await axiosInstance.get(`/disccountCode/${id}`);
  return res;
};

export const putDisccountCodeById = async (id, data) => {
  const res = await axiosInstance.put(`/disccountCode/${id}`, data);
  return res;
};

export const deleteDisccountCodeById = async (id) => {
  const res = await axiosInstance.delete(`/disccountCode/${id}`);
  return res;
};

export const getUserInfo = async () => {
  const response = await axiosInstance.get("/session");
  return response;
};

export async function getPollQuestions(params) {
  const response = await axiosInstance.get("pollQuestion", {
    params: {
      campaign_id: params?.query.campaign_id,
    },
  });
  return response;
}

export async function postPollQuestion(params) {
  const response = await axiosInstance.post("pollQuestion", params?.body, {
    params: params?.query,
  });
  return response;
}

export async function getPollQuestionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.get(`pollQuestion/${params.path.id}`, {
    params: params?.query,
  });
  return response;
}

export async function putPollQuestionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(
    `pollQuestion/${params.path.id}`,
    params?.body,
    {
      params: params?.query,
    }
  );
  return response;
}

export async function deletePollQuestionId(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.delete(
    `pollQuestion/${params.path.id}`,
    {
      params: params?.query,
    }
  );
  return response;
}

export async function getFeedback() {
  const res = await axiosInstance.get("feedback");
  return res;
}

export async function putFeedback(params) {
  const requiredPathFields = ["id"];
  for (const field of requiredPathFields) {
    if (!params.path.hasOwnProperty(field)) {
      throw new Error(`Missing required path field: ${field}`);
    }
  }
  const response = await axiosInstance.put(
    `feedback/${params.path.id}`,
    params?.body,
    {
      params: params?.query,
    }
  );
  return response;
}

// user info
export async function getUsers(params) {
  const response = await axiosInstance.get("/user", {
    params: params?.query,
  });
  return response;
}

export async function getPlayers(params) {
  const response = await axiosInstance.get("/player", {
    params: params?.query,
  });
  return response;
}

// user page data
export async function getUserPageData(params) {
  const response = await axiosInstance.get("userData", {
    params: params?.query,
  });
  return response;
}

export async function postUpdateUserPoints(params) {
  const response = await axiosInstance.post("/user/updatePoints", params?.body);
  return response;
}
export async function postRemoveUserPoints(params) {
  const response = await axiosInstance.post("/user/removePoints", params?.body);
  return response;
}
export async function postGiveRewardToUser(params) {
  const response = await axiosInstance.post("/user/giveReward", params?.body);
  return response;
}

export async function postToggleUserBlocked(body) {
  return await axiosInstance.post("/user/toggleBlocked", body);
}

export async function postRemoveUserReward(params) {
  const response = await axiosInstance.post("/user/removeReward", params?.body);
  return response;
}

export async function getGames(query) {
  return await axiosInstance.get("game", {
    params: query,
  });
}

export async function getScheduleStatus() {
  return await axiosInstance.get("/games/schedule/status");
}

export async function postScheduleUpdate(payload) {
  return await axiosInstance.post("/games/schedule/update", payload);
}

export async function deleteGame(gameId) {
  return await axiosInstance.delete(`game/${gameId}`);
}
