import { useRef } from "react";
import Logo from "../../assets/logo_horizontal.svg";
import useMutationObservable from "../../hooks/useMutationObservable";
import cls from "classnames";
import { useLocation } from "react-router-dom";
import { Squash as Hamburger } from "hamburger-react";
import { useMediaQuery, useToggle } from "usehooks-ts";
import { Layout as AntdLayout, Button } from "antd";
import { useSession } from "../../context/SessionContext";
import { Link } from "react-router-dom";
const { Content, Header } = AntdLayout;

export default function Layout({ header, children, ...props }) {
  const { user, logout } = useSession();
  const [isOpen, toggleOpen, setToggle] = useToggle();
  const matches = useMediaQuery("(max-width: 992px)");
  const wrapper = useRef(null);
  const { pathname } = useLocation();
  const campaignPage = pathname.includes("/campaign/");
  
  useMutationObservable(wrapper.current, () => {
    const isOpenMenu = wrapper.current.classList.contains(
      "admin-panel-menu-open"
    );

    if (!isOpenMenu) {
      setToggle(false);
    }
  });

  return (
    <AntdLayout className="layout">
      {header && (
        <Header className="header d-flex align-items-center justify-content-between px-3 my-3">
          <nav className="d-flex justify-content-between w-100 admin-panel-nav-menu position-relative">
            <Link to={`/admin/company/${user?.company}`}>
              <img
                src={Logo}
                className="d-block"
                alt="Swish Nation Logo"
                height={50}
                width={180}
              />
            </Link>

            <section className="d-flex align-items-center gap-2 nav-temp">
              {/* <Text className="fs-5">Company Settings</Text>
              <Text className="fs-5">Create Campaign</Text> */}
              {user && (
                <>
                  <Link to={"/createInBatch"}>
                    <Button className="ghost">Create in Batch</Button>
                  </Link>
                  <Link to={"/games"}>
                    <Button className="ghost">Games (NBA)</Button>
                  </Link>
                  <Link to={"/feed"}>
                    <Button className="ghost">Feed Manager</Button>
                  </Link>
                  <Link to={"/createPrediction"}>
                    <Button className="ghost">Create Prediction</Button>
                  </Link>
                  <Link to={`/admin/company/${user?.company}/feedback`}>
                    <Button className="ghost">Feedback</Button>
                  </Link>
                </>
              )}
              {user?.isAdmin && (
                <Link to="/api-keys">
                  <Button className="ghost">Api Keys</Button>
                </Link>
              )}

              {user && <Button onClick={logout}>Logout</Button>}
            </section>

            {matches && campaignPage && (
              <div
                className="position-absolute"
                style={{ zIndex: 999, right: 0, top: 0 }}
              >
                <Hamburger size={25} toggle={toggleOpen} />
              </div>
            )}
          </nav>
        </Header>
      )}

      <Content className="d-flex justify-content-center" {...props}>
        <div
          ref={(el) => {
            wrapper.current = el;
          }}
          className={cls("wrapper mb-4 w-100 h-100 px-3", {
            "admin-panel-menu-open": isOpen,
          })}
        >
          {children}
        </div>
      </Content>
    </AntdLayout>
  );
}
