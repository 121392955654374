import Async from "../components/LazyComponent";
import moment from "moment";
import VideoChallenge from "../assets/video-challenge.svg";
import ImageChallenge from "../assets/photo-challenge.svg";
import TextChallenge from "../assets/text-challenge.svg";
import { enUS } from "date-fns/locale";
import { formatDistanceToNowStrict } from "date-fns";

export function getFormattedDistanceToNow(date) {
  const options = {
    locale: {
      ...enUS,
      formatDistance: (unit, count) => {
        const units = {
          xDays: `${count}d ago`,
          xHours: `${count}h ago`,
          xMinutes: `${count}mins ago`,
          xMonths: `${count}mo ago`,
          xSeconds: `${count} secs ago`,
          xYears: `${count}y ago`,
        };
        return units[unit] || "%d h ago";
      },
    },
  };

  return formatDistanceToNowStrict(new Date(date), options);
}

export const route = (component, path = "*", rest) => {
  return {
    element: Async(component),
    path,
    ...rest,
  };
};

export const privateRoute = (component, path = "/", props) => {
  return route(component, path, { private: true, ...props });
};

export const redirectRoute = (component, path = "/", props) => {
  return route(component, path, { redirect: true, ...props });
};

export const publicRoute = (component, path = "/", props) => {
  return route(component, path, { public: true, ...props });
};

export const getFormattedDate = (datetime) => {
  try {
    const date = new Date(datetime);
    const yy = date.getFullYear() % 100;
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedDate = dd + "/" + mm + "/" + yy;
    return formattedDate;
  } catch (err) {
    console.error(err);
    return "NaN";
  }
};

export const getDaysDiffDates = (date) => {
  const start = moment().format("YYYY-MM-DD");
  const end = moment(date, "YYYY-MM-DD");

  const days = moment.duration(end.diff(start)).asDays();
  return days;
};

export const getImage = (mimeType = "") => {
  if (mimeType.startsWith("video")) return VideoChallenge;
  if (mimeType.startsWith("image")) return ImageChallenge;
  return TextChallenge;
};

export function normalizeText(text) {
  const result = text
    .normalize("NFD")
    .trim()
    .replace(/[\u0300-\u036f]/g, "");
  return result;
}

export function includeStrings(str1 = "", str2 = "") {
  const s1 = normalizeText(String(str1).toLowerCase());
  const s2 = normalizeText(String(str2).toLowerCase());

  return s1 === s2 || s1.includes(s2);
}

export function generateCode() {
  const code = (Math.random() + 1).toString(36).substring(2, 7).toUpperCase();
  return code;
}

function dec2hex(dec) {
  return dec.toString(16).padStart(2, "0");
}

export function generateId(len = 10) {
  const arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join("");
}

export function isEmpty(str = "") {
  if (typeof str !== "string") return true;

  const s = str.trim();
  return s.length < 1;
}

export function getError(err) {
  if (!err) return "Oops something failed, please try again.";

  const { response } = err;
  if (typeof response?.data === "string" && response?.data) {
    return response.data;
  }

  const errorObj = response?.data?.err;

  if (!errorObj) {
    if (response?.data?.message) return response.data.message.toString();
    if (response?.message) return response.message.toString();

    return err.message;
  }

  if (typeof errorObj === "string") return errorObj;

  const errors = Object.values(errorObj);
  return errors.join(", ");
}

export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => resolve(fr.result);
    fr.onerror = (err) => reject(err);
    fr.readAsDataURL(file);
  });
}

export function videoToURL(videoFile) {
  const newObjectUrl = URL.createObjectURL(videoFile);
  return newObjectUrl;
}

export const isMongoId = (str) => str.length === 24 && /^[A-F0-9]+$/i.test(str);

export function getShortText(text, maxLength = 230) {
  const isLongText = text.length > maxLength;
  const shortText = isLongText ? text.substring(0, maxLength) + "..." : text;
  return shortText;
}

const cache = new Map();

export function deepEqual(x, y) {
  const cacheKey = `deepEqual-${x}-${y}`;

  if (cache.has(cacheKey)) {
    return cache.get(cacheKey);
  }

  let isEqual = false;

  if (x === y) {
    isEqual = true;
  } else if (typeof x === "number" && typeof y === "number") {
    isEqual = x === y;
  } else if (!isNaN(Number(x)) || !isNaN(Number(y))) {
    return parseInt(x) === parseInt(y);
  } else if (typeof x === "string" || typeof y === "string") {
    isEqual = includeStrings(x, y);
  } else if (
    typeof x === "object" &&
    x !== null &&
    typeof y === "object" &&
    y !== null
  ) {
    const keysX = Object.keys(x);
    const keysY = Object.keys(y);

    if (keysX.length === keysY.length) {
      isEqual = keysX.every(
        (key) => keysY.includes(key) && deepEqual(x[key], y[key])
      );
    }
  }

  cache.set(cacheKey, isEqual);
  return isEqual;
}

export const getNotationPropValue = (t, path) =>
  path.split(".").reduce((r, k) => r?.[k], t);
