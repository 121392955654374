import React from "react";
import ReactDOM from "react-dom/client";
import Routers from "./Routers";
import { SessionProvider } from "./context/SessionContext";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import { ConfigProvider, theme } from "antd";
import "inter-ui/inter.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-alice-carousel/lib/alice-carousel.css";
import "./style/global.scss";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 0,
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ConfigProvider
    theme={{
      algorithm: theme.darkAlgorithm,
      token: {
        fontFamily:
          'Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
        colorPrimary: "#ff1fa5",
        colorBgBase: "#161924",
        colorBgContainer: "#000",
        borderRadius: 6,
      },
    }}
  >
    <QueryClientProvider client={queryClient}>
      <SessionProvider>
        <Routers />
      </SessionProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ConfigProvider>
);
